import { Task } from "./types/public-types"; // Importing Task type

// Interface defining the props for initTimeline function
interface initTimelineProps {
  timeline: any[];
}

// Function to initialize tasks from the provided timeline
export function initTimeline({ timeline }: initTimelineProps) {
  // Mapping over the timeline to create an array of tasks
  const tasks: Task[] = timeline.map((event) => {
    // Set start and end dates based on event data
    var start = !event.event_date ? new Date() : new Date(event.event_date);
    var end = !event.event_end ? start : new Date(event.event_end);
    var name = !event.event_title ? "" : event.event_title;

    // Return a task object
    return {
      start: start,
      end: end,
      name: name,
      id: String(event.id),
      grouping: event.event_type,
      progress: 100,
      type: "task",
      author: event.author,
      author_role: event.author_role,
      event_abstract: event.event_abstract,
      displayOrder: event.id,
      link_to_pdf: event.link_to_pdf,
    };
  });

  return tasks; // Return the array of tasks
}
