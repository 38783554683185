import React, { useState } from "react"; // Importing React and useState hook
import {
  FormControlLabel,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"; // Importing MUI components
import { ViewMode } from "gantt-task-react"; // Importing ViewMode from gantt-task-react

import "./gantt.css"; // Importing custom CSS

// Type definition for ViewSwitcherProps
type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};

// ViewSwitcherMui component definition
export const ViewSwitcherMui: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  // State to manage the current view mode
  const [whichView, setWhichView] = useState<string>("Year");

  // Handler for view mode change
  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newWhichView: string
  ) => {
    setWhichView(newWhichView); // Update the state with the new view mode
  };

  return (
    <div className="ViewContainer">
      {/* ToggleButtonGroup for selecting the view mode */}
      <ToggleButtonGroup
        color="primary"
        value={whichView}
        exclusive
        onChange={handleViewChange}
        size="small"
        sx={{
          mb: 2,
        }}
      >
        {/* Toggle buttons for different view modes */}
        <ToggleButton
          value="Day"
          onClick={() => onViewModeChange(ViewMode.Day)}
        >
          Day
        </ToggleButton>
        <ToggleButton
          value="Week"
          onClick={() => onViewModeChange(ViewMode.Week)}
        >
          Week
        </ToggleButton>
        <ToggleButton
          value="Month"
          onClick={() => onViewModeChange(ViewMode.Month)}
        >
          Month
        </ToggleButton>
        <ToggleButton
          value="Year"
          onClick={() => onViewModeChange(ViewMode.Year)}
        >
          Year
        </ToggleButton>
      </ToggleButtonGroup>
      {/* FormControlLabel with a Switch to toggle event list visibility */}
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            onChange={() => onViewListChange(!isChecked)}
            name="checked"
            color="primary"
          />
        }
        label="Show Event List"
        sx={{ ml: 1, mb: 2 }}
      />
    </div>
  );
};
