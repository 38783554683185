// React imports
import React from "react";
import { useState } from "react"; // Importing useState hook from React

// MUI imports
import { Box, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Importing useTheme hook from MUI
import { orange } from "@mui/material/colors"; // Importing orange color from MUI

// Type imports
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";
import { Task } from "../chronology/gantt/types/public-types"; // Importing Task type

// Component imports
import { ViewMode, Gantt } from "gantt-task-react"; // Importing Gantt and ViewMode from gantt-task-react
import { ViewSwitcherMui } from "../chronology/gantt/viewSwitcherMui"; // Importing custom ViewSwitcherMui component
import { ganttInitTimeline } from "./ganttInitTimeline"; // Importing ganttInitTimeline function

const GanttTimeline = ({ timeline }: { timeline: PatientTimeline[] }) => {
  const theme = useTheme(); // Using MUI theme
  const [view, setView] = useState<ViewMode>(ViewMode.Year); // State to manage the current view mode
  const [tasks, setTasks] = useState<Task[]>(ganttInitTimeline({ timeline })); // State to manage the tasks
  const [isChecked, setIsChecked] = useState(false); // State to manage the checkbox status

  // Set the column width based on the current view mode
  let columnWidth = 120;
  if (view === ViewMode.Month) {
    columnWidth = 500; // Set column width to 500 for Month view
  } else if (view === ViewMode.Week) {
    columnWidth = 250; // Set column width to 250 for Week view
  } else if (view === ViewMode.Year) {
    columnWidth = 500; // Set column width to 500 for Year view
  }
  /**
   * Updates the task list with the modified task.
   *
   * @param {Task} task - The task object with updated information.
   */
  const handleTaskChange = (task: Task) => {
    // Create a new array of tasks, replacing the task with the matching id
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    setTasks(newTasks); // Update the state with the new array of tasks
  };

  /**
   * Deletes a task from the task list.
   *
   * @param {Task} task - The task object to be deleted.
   */
  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id)); // Remove the task from the state
    }
    return conf;
  };

  /**
   * Updates the progress of a task.
   *
   * @param {Task} task - The task object with updated progress.
   */
  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t))); // Update the state with the new task progress
  };

  /**
   * Handles double-click event on a task.
   *
   * @param {Task} task - The task object that was double-clicked.
   */
  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  /**
   * Handles selection of a task.
   *
   * @param {Task} task - The task object that was selected.
   * @param {boolean} isSelected - Whether the task is selected or not.
   */
  const handleSelect = (task: Task, isSelected: boolean) => {};

  /**
   * Handles expander click event on a task.
   *
   * @param {Task} task - The task object that was expanded.
   */
  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t))); // Update the state with the expanded task
  };

  return (
    <Box sx={{ borderTop: "1px solid " + theme.palette.divider, pt: 2 }}>
      {/* ViewSwitcherMui component to switch between different view modes */}
      <ViewSwitcherMui
        onViewModeChange={setView}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />
      {/* Gantt component to display the timeline */}
      <Gantt
        tasks={tasks}
        viewMode={view}
        viewDate={new Date(2023, 2, 23, 18, 0, 0, 0)}
        onDateChange={handleTaskChange}
        onDelete={handleTaskDelete}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onSelect={handleSelect}
        onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "175px" : ""}
        fontFamily="Nunito,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'"
        columnWidth={columnWidth}
        projectBackgroundColor={orange[700]}
        projectBackgroundSelectedColor={orange[700]}
        projectProgressColor={orange[700]}
        projectProgressSelectedColor={orange[700]}
        barProgressColor={theme.palette.primary.main}
        barProgressSelectedColor={theme.palette.secondary.main}
        TooltipContent={({ task }) => {
          return (
            <Paper variant="outlined" sx={{ px: 5, py: 4 }}>
              {/* Display task grouping if available */}
              {task.grouping && (
                <Typography variant="overline">
                  <strong>{task.grouping}</strong>{" "}
                </Typography>
              )}
              {/* Display task name */}
              <Typography variant="h5">{task.name}</Typography>

              {/* Display task author if available */}
              {task.author && (
                <Typography variant="body1">
                  <strong>{task.author}</strong>
                </Typography>
              )}
              {/* Display task author role if available */}
              <Typography
                variant="body1"
                color="GrayText"
                sx={{ textTransform: "uppercase" }}
              >
                {task.author_role && task.author_role}
              </Typography>

              {/* Display task abstract if available */}
              {task.event_abstract && (
                <Typography sx={{ maxWidth: 300, mt: 3, pb: 3 }}>
                  {task.event_abstract}
                </Typography>
              )}
              {/* Display task link to PDF if available */}
              {/* {task.link_to_pdf && (
                <Link
                  href={task.link_to_pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  underline="hover"
                >
                  VIEW SOURCE
                </Link>
              )} */}
              {/* Display task start and end dates */}
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 3, justifyContent: "space-between" }}
              >
                <Box>
                  <Typography variant="body1">
                    <strong>{task.start.toLocaleDateString()}</strong>
                  </Typography>
                  <Typography variant="body1">
                    {task.start.toLocaleTimeString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" sx={{ textAlign: "right" }}>
                    <strong>{task.end.toLocaleDateString()}</strong>
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "right" }}>
                    {task.end.toLocaleTimeString()}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          );
        }}
      />
    </Box>
  );
};

export default GanttTimeline;
