import React from "react";
import Loader from "../../components/Loader";
// import { featureFlags } from "../../config";
import { usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery } from "../../api/jabsCentralApiPrivate";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedFileItem } from "../../redux/slices/fileSlice";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

const PatientFileListPage: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: patientFiles,
    error: patientFilesError,
    isLoading: isPatientFilesLoading,
  } = usePatientPatientIdFilesGetPatientPatientIdFilesGetQuery({
    patientId: "4",
  });

  if (isPatientFilesLoading) {
    return <Loader />;
  }

  if (patientFilesError) {
    return (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientFilesError)
          ? String(patientFilesError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }

  const handleItemClick = (item: string) => {
    dispatch(setSelectedFileItem(item));
  };

  const handleItemLink = (fileType: string) => {
    if (fileType) {
      return "/page/file-viewer";
    }

    return "/page/dicom-viewer";
  };

  return (
    <div>
      <table style={{ border: "1px solid black", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black" }}>File ID</th>
            <th style={{ border: "1px solid black" }}>File Type</th>
            <th style={{ border: "1px solid black" }}>Description</th>
            <th style={{ border: "1px solid black" }}>Date</th>
            <th style={{ border: "1px solid black" }}>Local Path</th>
          </tr>
        </thead>
        <tbody>
          {patientFiles?.files?.map((file: any) => {
            return (
              <tr key={file.fileId}>
                <td style={{ border: "1px solid black" }}>
                  <Link
                    to={{ pathname: handleItemLink(file.filetype) }}
                    onClick={() => handleItemClick(file.fileId)}
                  >
                    {file.fileId}
                  </Link>
                </td>
                <td style={{ border: "1px solid black" }}>{file.filetype}</td>
                <td style={{ border: "1px solid black" }}>
                  {file.description}
                </td>
                <td style={{ border: "1px solid black" }}>{file.createDate}</td>
                <td style={{ border: "1px solid black" }}>{file.filename}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ); // <DicomViewerComponent dicomJSONurl="https://viewer.dev.jabs.ai/LIDC-IDRI-0001.json" />
};

export default PatientFileListPage;
