import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../Loader";

import { usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery } from "../../api/jabsCentralApiPrivate";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const GetUpdates: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientUpdates,
    error: patientUpdatesError,
    isLoading: isPatientUpdatesLoading,
    isFetching: isPatientUpdatesFetching,
  } = usePatientPatientIdUpdatesGetPatientPatientIdUpdatesGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientUpdatesLoading) {
    content = <Loader />;
  } else if (isPatientUpdatesFetching) {
    content = <Loader />;
  } else if (patientUpdatesError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientUpdatesError)
          ? JSON.stringify(patientUpdatesError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientUpdates) {
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientUpdates, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography variant="h4">GET /patient/[patientId]/updates</Typography>
      {content}
    </div>
  );
};

export default GetUpdates;
