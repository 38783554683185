import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../Loader";

import { usePatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetQuery } from "../../api/jabsCentralApiPrivate";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const GetMeasurements: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientMeasurements,
    error: patientMeasurementsError,
    isLoading: isPatientMeasurementsLoading,
    isFetching: isPatientMeasurementsFetching,
  } = usePatientPatientIdMeasurementsGetPatientPatientIdMeasurementsGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientMeasurementsLoading) {
    content = <Loader />;
  } else if (isPatientMeasurementsFetching) {
    content = <Loader />;
  } else if (patientMeasurementsError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientMeasurementsError)
          ? JSON.stringify(patientMeasurementsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientMeasurements) {
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientMeasurements, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography variant="h4">
        GET /patient/[patientId]/measurements
      </Typography>
      {content}
    </div>
  );
};

export default GetMeasurements;
