// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import {
  Box,
  IconButton,
  Typography,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { AddCircle, ViewTimeline, ViewList, GridOn } from "@mui/icons-material";

// API imports
import { usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery } from "../../api/jabsCentralApiPrivate";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";

// Component imports
import Loader from "../Loader";
import VerticalTimeline from "./VerticalTimeline";
import TableTimeline from "./TableTimeline";
import GanttTimeline from "./GanttTimeline";

// Function to handle adding a new item
const handleNewItem = () => {
  console.log("Adding new item");
};

// Interface defining the props for Chronology component
interface ChronologyProps {
  patientId: string;
  title: string;
}

// Styled component for wrapping text

const Chronology = ({ patientId, title }: ChronologyProps) => {
  const viewMode = localStorage.getItem("view"); // Get the view mode from local storage
  const initialView = viewMode ? viewMode : "vertical"; // Set the initial view mode to vertical if not found in local storage
  // State to manage the current view mode (vertical or gantt)
  const [view, setView] = useState(initialView);
  // Handler for changing the view mode
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    if (nextView === null) {
      return;
    }
    localStorage.setItem("view", nextView); // Save the view mode to local storage
    setView(nextView); // Update the state with the new view mode
  };

  // Using the API hook to fetch patient timeline data
  const {
    data: patientTimeline,
    error: patientTimelineError,
    isLoading: isPatientTimelineLoading,
    isFetching: isPatientTimelineFetching,
    // refetch: refetchPatientTimeline,
  } = usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery(
    {
      patientId: patientId,
    },
    { refetchOnMountOrArgChange: true }
  );

  // Variable to hold the content to be displayed
  let content = null;

  // Handling different states of the API call
  if (isPatientTimelineLoading) {
    // Show loader while loading
    content = <Loader />;
  } else if (isPatientTimelineFetching) {
    // Show loader while fetching
    content = <Loader />;
  } else if (patientTimelineError) {
    // Show error message if there's an error
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientTimelineError)
          ? JSON.stringify(patientTimelineError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    // Show the patient timeline data
    if (!patientTimeline) {
      // Show message if no patient is selected
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <React.Fragment>
          {/* Header with title and toggle button group for switching views */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2, width: "100%" }}
          >
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <IconButton onClick={handleNewItem}>
                <AddCircle />
              </IconButton>
              {/* Title */}
              <Typography variant="h4" sx={{ mb: 0, ml: 1, flexGrow: 1 }}>
                {title}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              <Typography variant="h6" sx={{ mr: 1 }}>
                <strong>View:</strong>
              </Typography>
              <Box sx={{ width: "75px" }}>
                <Typography variant="h6" sx={{ fontWeight: "300" }}>
                  {view === "vertical" && " Timeline"}
                  {view === "table" && " Table"}
                  {view === "gantt" && " Gantt"}
                </Typography>
              </Box>

              {/* ToggleButtonGroup for selecting the view mode */}
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleChange}
                aria-label="view"
                size="small"
                color="primary"
              >
                {/* Toggle button for vertical view */}
                <ToggleButton
                  value="vertical"
                  aria-label="vertical"
                  title="Timeline View"
                >
                  <ViewList />
                </ToggleButton>
                {/* Toggle button for table view */}
                <ToggleButton
                  value="table"
                  aria-label="table"
                  title="Table View"
                >
                  <GridOn />
                </ToggleButton>
                {/* Toggle button for gantt view */}
                <ToggleButton
                  value="gantt"
                  aria-label="gantt"
                  title="Gantt View"
                >
                  <ViewTimeline />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
          {/* Conditional rendering of VerticalTimeline, TableTimeline or ganttTimeline based on the selected view */}
          {view === "vertical" && Array.isArray(patientTimeline) && (
            <VerticalTimeline timeline={patientTimeline} />
          )}
          {view === "table" && Array.isArray(patientTimeline) && (
            <TableTimeline timeline={patientTimeline} />
          )}
          {view === "gantt" && Array.isArray(patientTimeline) && (
            <GanttTimeline timeline={patientTimeline} />
          )}
        </React.Fragment>
      );
    }
  }
  // Render the component
  return <div>{content}</div>;
};

export default Chronology;
