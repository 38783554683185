import React from "react";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import DashboardLayout from "./layouts/Dashboard";
// import CleanLayout from "./layouts/Clean";
import Legal from "./layouts/Legal";

// Page components
import AdminUploadPage from "./pages/pages/AdminUploadPage";
import ApiTestPage from "./pages/pages/ApiTestPage";
import Blank from "./pages/pages/Blank";
import CaseDashboard from "./pages/mocks/legal/CaseDashboard";
import DICOMViewerPage from "./pages/pages/DicomViewerPage";
import FileViewerPage from "./pages/pages/FileViewerPage";
import PatientFileListPage from "./pages/pages/PatientFileListPage";
import PatientListTestPage from "./pages/pages/PatientListTest";
import PatientViewPage from "./pages/pages/PatientView";
import TextTest from "./pages/pages/TextTest";

import PatientViewMockPage from "./pages/mocks/PatientViewMock";
import PatientTimelineMockPage from "./pages/mocks/PatientTimelineMock";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,

    children: [
      {
        path: "/",
        element: <Blank />,
      },

      {
        path: "list/ward-a",
        element: <PatientListTestPage />,
      },
      {
        path: "list/ward-b",
        element: <PatientListTestPage />,
      },

      {
        path: "page/blank",
        element: <Blank />,
      },
      {
        path: "page/text-test",
        element: <TextTest />,
      },
      {
        path: "page/patient-file-list",
        element: <PatientFileListPage />,
      },
      {
        path: "page/dicom-viewer",
        element: <DICOMViewerPage />,
      },
      {
        path: "page/file-viewer",
        element: <FileViewerPage />,
      },
    ],
  },
  {
    path: "/admin",
    element: <Legal />,
    children: [
      {
        path: "/admin",
        element: <AdminUploadPage />,
      },
      {
        path: "/admin/api-test",
        element: <ApiTestPage />,
      },
      {
        path: "/admin/patient-view",
        element: <PatientViewPage />,
      },
      {
        path: "admin/patient-view/:id",
        element: <PatientViewPage />,
      },
    ],
  },
  {
    path: "/mock-ups",
    element: <Legal />,
    children: [
      {
        path: "/mock-ups/patient-view",
        element: <PatientViewMockPage />,
      },
      {
        path: "/mock-ups/patient-timeline",
        element: <PatientTimelineMockPage />,
      },
      {
        path: "/mock-ups/case-dashboard",
        element: <CaseDashboard />,
      },
    ],
  },
];

export default routes;
