// React imports
import React from "react";

// MUI imports
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// API imports
import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

// Component imports
import ChronologyEvent from "./ChronologyEvent";

// Styled component for the timeline container
const TimelineContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "20px",
}));

const VerticalTimeline = ({ timeline }: { timeline: PatientTimeline[] }) => {
  console.log("VerticalTimeline: ", timeline);

  return (
    <TimelineContainer>
      {timeline.map((event: PatientTimeline, index) => {
        // Determine the line type for the event
        var lineType = "middle";

        if (timeline.length === 1) {
          lineType = "single";
        } else if (index === 0) {
          lineType = "top";
        } else if (index === timeline.length - 1) {
          lineType = "bottom";
        }

        // Render the event with the appropriate line type
        return (
          <>
            <ChronologyEvent key={index} lineType={lineType} event={event} />
          </>
        );
      })}
    </TimelineContainer>
  );
};

export default VerticalTimeline;
