import React from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
// import PatientList from "../../components/admin/PatientList";
import PatientDrawer from "../../components/patientSelection/PatientDrawer";

import TLPagebar from "../../components/navbar/appBar/TLPageBar";

const PageContent = styled(Box)`
  padding: 12px 24px 24px 24px;
`;

function PatientViewPage() {
  return (
    <React.Fragment>
      <Helmet title="Patient View" />
      <TLPagebar title="Patient View" rightTools={<PatientDrawer />} />
      <PageContent></PageContent>
    </React.Fragment>
  );
}

export default PatientViewPage;
