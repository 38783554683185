// import { featureFlags } from "../../config";
import { SidebarItemsType } from "../../types/sidebar";

import {
  Api,
  Brush,
  CloudUpload,
  Dashboard,
  Home,
  Person,
  Preview,
  ViewTimeline,
} from "@mui/icons-material";

const homeSection = [
  {
    href: "/",
    icon: Home,
    title: "Home",
    amount: 0,
    category: "Home",
  },
] as SidebarItemsType[];

const screensSection = [
  {
    href: "/pages",
    icon: Preview,
    title: "PAGES",
    children: [
      {
        href: "/admin/",
        icon: CloudUpload,
        title: "Admin Upload",
      },
      {
        href: "/admin/api-test",
        icon: Api,
        title: "API Test",
      },
      {
        href: "/admin/patient-view",
        icon: Person,
        title: "Patient View",
      },
    ],
  },
] as SidebarItemsType[];
const mocksSection = [
  {
    href: "/mock-ups",
    icon: Brush,
    title: "MOCK-UPS",
    children: [
      {
        href: "/mock-ups/patient-view",
        icon: Person,
        title: "Patient",
      },
      {
        href: "/mock-ups/patient-timeline",
        icon: ViewTimeline,
        title: "Timeline",
      },
      {
        href: "/mock-ups/case-dashboard",
        icon: Dashboard,
        title: "Cases",
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: homeSection,
    custom: false,
  },

  {
    title: "Pages",
    pages: screensSection,
    custom: true,
  },
  {
    title: "Mock-ups",
    pages: mocksSection,
    custom: true,
  },
];

export default navItems;
