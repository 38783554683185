import React from "react";
import {
  AppBar,
  Box,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

import styled from "@emotion/styled";

import Spacer from "../../navbar/appBar/Spacer";

// Styled component for the patient bar
const PatientBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// Component to display a prompt when no patient is selected
const PatientDetailsPrompt: React.FC = () => {
  const listHeight = 80; // Height for the skeleton list items

  return (
    <React.Fragment>
      {/* AppBar with skeletons for patient details */}
      <PatientBar position="sticky">
        <Toolbar>
          <Box sx={{ px: 4 }}>
            <Skeleton
              variant="text"
              width={120}
              sx={{ backgroundColor: "#ffffff22" }}
            />
            <Skeleton
              variant="text"
              width={120}
              sx={{ backgroundColor: "#ffffff22" }}
            />
          </Box>
          <Box>
            <Skeleton
              variant="text"
              width={150}
              sx={{ backgroundColor: "#ffffff22" }}
            />
            <Skeleton
              variant="text"
              width={150}
              sx={{ backgroundColor: "#ffffff22" }}
            />
          </Box>
          <Spacer />
        </Toolbar>
      </PatientBar>

      {/* Main content area with prompt message */}
      <Box sx={{ py: 6, px: 6 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={8}>
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <AccountCircle color="disabled" sx={{ fontSize: 80 }} />
              <Typography variant="h5">Select patient from</Typography>
              <Typography variant="h5">the list to view details.</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Skeleton
              variant="text"
              width={200}
              sx={{ fontSize: 18, ml: 2, mb: 2 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
            <Skeleton
              variant="text"
              width={160}
              sx={{ fontSize: 13, ml: 2, mb: 1 }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt: 6, mb: 6 }} />

        <Grid container spacing={6}>
          <Grid item xs={12} lg={4}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ ml: 0, mb: 2 }}
              />
              <Skeleton
                variant="rounded"
                height={20}
                width={200}
                sx={{ ml: 2, mb: 2 }}
              />
            </Stack>
            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ ml: 0, mb: 2 }}
              />
              <Skeleton
                variant="rounded"
                height={20}
                width={200}
                sx={{ ml: 2, mb: 2 }}
              />
            </Stack>
            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                sx={{ ml: 0, mb: 2 }}
              />
              <Skeleton
                variant="rounded"
                height={20}
                width={200}
                sx={{ ml: 2, mb: 2 }}
              />
            </Stack>
            <Skeleton variant="rounded" height={listHeight} />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default PatientDetailsPrompt;
