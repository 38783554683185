import { Task } from "../chronology/gantt/types/public-types";

interface initTimelineProps {
  timeline: any[];
}

export function ganttInitTimeline({ timeline }: initTimelineProps) {
  const tasks: Task[] = timeline.map((event) => {
    var start = !event.eventDateTime
      ? new Date()
      : new Date(event.eventDateTime);
    var end = !event.event_end ? start : new Date(event.event_end);
    var name = !event.eventTitle ? "" : event.eventTitle;

    // Return a task object
    return {
      start: start,
      end: end,
      name: name,
      id: String(event.eventId),
      grouping: event.eventType,
      progress: 100,
      type: "task",
      author: event.author,
      author_role: event.author_role,
      event_abstract: event.eventDescription,
      displayOrder: event.id,
      link_to_pdf: event.fileUrl,
    };
  });

  return tasks; // Return the array of tasks
}
