import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// Importing necessary icons from MUI
import { ViewTimeline, ViewList, GridOn } from "@mui/icons-material";

// Importing necessary components from MUI
import {
  Box,
  Typography,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";

// Importing custom components
import VerticalTimeline from "./VerticalTimeline";
import TableTimeline from "./TableTimeline";
import HorizontalTimeline from "./gantt/HorizontalTimeline";
import NewEventForm from "./ChronologyNewEventForm";

// Interface defining the props for Chronology component
interface ChronologyProps {
  title: string;
}

// Chronology component definition
const Chronology = ({ title }: ChronologyProps) => {
  const timeline = useSelector(
    (state: RootState) => state.changeMockTimeline.mockTimeline
  ); // Get the timeline data from the Redux store
  const [openNewEventForm, setOpenNewEventForm] = useState(false); // State to manage the visibility of the new event form
  // Function to handle adding a new item
  const handleNewItem = () => {
    setOpenNewEventForm(true); // Open the new event form
  };

  const handleCloseNewEventForm = () => {
    setOpenNewEventForm(false); // Close the new event form
  };

  const viewMode = localStorage.getItem("view"); // Get the view mode from local storage
  const initialView = viewMode ? viewMode : "vertical"; // Set the initial view mode to vertical if not found in local storage
  // State to manage the current view mode (vertical or horizontal)
  const [view, setView] = React.useState(initialView);

  // Handler for changing the view mode
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    if (nextView === null) {
      return;
    }
    localStorage.setItem("view", nextView); // Save the view mode to local storage
    setView(nextView); // Update the state with the new view mode
  };

  return (
    <React.Fragment>
      {/* Conditional rendering of the new event form */}

      <NewEventForm
        timeline={timeline}
        open={openNewEventForm}
        handleClose={handleCloseNewEventForm}
      />

      {/* Header with title and toggle button group for switching views */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, width: "100%" }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <IconButton onClick={handleNewItem}>
            <AddCircle />
          </IconButton>
          {/* Title */}
          <Typography variant="h4" sx={{ mb: 0, ml: 1, flexGrow: 1 }}>
            {title}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Typography variant="h6" sx={{ mr: 1 }}>
            <strong>View:</strong>
          </Typography>
          <Box sx={{ width: "75px" }}>
            <Typography variant="h6" sx={{ fontWeight: "300" }}>
              {view === "vertical" && " Timeline"}
              {view === "table" && " Table"}
              {view === "gantt" && " Gantt"}
            </Typography>
          </Box>

          {/* ToggleButtonGroup for selecting the view mode */}
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChange}
            aria-label="view"
            size="small"
            color="primary"
          >
            {/* Toggle button for vertical view */}
            <ToggleButton
              value="vertical"
              aria-label="vertical"
              title="Timeline View"
            >
              <ViewList />
            </ToggleButton>
            {/* Toggle button for table view */}
            <ToggleButton value="table" aria-label="table" title="Table View">
              <GridOn />
            </ToggleButton>
            {/* Toggle button for gantt view */}
            <ToggleButton value="gantt" aria-label="gantt" title="Gantt View">
              <ViewTimeline />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      {/* Conditional rendering of VerticalTimeline, TableTimeline or HorizontalTimeline based on the selected view */}
      {view === "vertical" && <VerticalTimeline timeline={timeline} />}
      {view === "table" && <TableTimeline timeline={timeline} />}
      {view === "gantt" && <HorizontalTimeline timeline={timeline} />}
    </React.Fragment>
  );
};

export default Chronology;
