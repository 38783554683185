import React from "react";
import { usePatientGetPatientGetQuery } from "../../api/jabsCentralApiPrivate";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { setSelectedPatient } from "../../redux/slices/selectedPatientSlice";

import Loader from "../Loader";

// Type guard to check if the error is a FetchBaseQueryError
const isFetchBaseQueryError = (
  error: any
): error is { status: number; data: unknown } => {
  return typeof error === "object" && "status" in error && "data" in error;
};

// Define the columns for the DataGrid
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "firstName", headerName: "First name", width: 100 },
  { field: "lastName", headerName: "Last name", width: 100 },
  {
    field: "dob",
    headerName: "DOB",
    renderCell: (params: GridRenderCellParams) => {
      // Render the date of birth in a localized date string format
      return new Date(params.value as string).toLocaleDateString();
    },
    width: 150,
  },
];

// Component to display the list of patients
const PatientList: React.FC = () => {
  const dispatch = useDispatch();

  // Fetch the list of patients using the custom hook
  const {
    data: patients,
    error: patientsError,
    isLoading: isPatientsLoading,
  } = usePatientGetPatientGetQuery({ limit: 1000 });

  // Show a loader while the data is being fetched
  if (isPatientsLoading) {
    return <Loader />;
  }

  // Show an error message if there was an error fetching the data
  if (patientsError) {
    return (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientsError)
          ? String(patientsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  }

  // Handle cell click event to select a patient
  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field !== "actions") {
      dispatch(setSelectedPatient(params.id as number));
    }
  };

  // Render the DataGrid with the fetched patient data
  return (
    <div>
      <DataGrid
        rows={patients?.patients || []}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
        pageSizeOptions={[5, 10, 25, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        autoHeight
        onCellClick={handleCellClick}
      />
    </div>
  );
};

export default PatientList;
