// React Imports
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

// Utils Imports

// MUI Imports
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";

// Data imports
import exampleDoctors from "../../mockData/exampleDoctors";
import { Timeline } from "../../types/extraction";
import { changeCommentTimeline } from "../../redux/slices/mockTimelineSlice";

// Interface for the component props
interface NewEventFormProps {
  timeline: Timeline; // Timeline data
  open: boolean; // Boolean to control the dialog open state
  handleClose: () => void; // Function to handle closing the dialog
}

// Functional component for the new event form
const NewEventForm = ({ timeline, open, handleClose }: NewEventFormProps) => {
  const dispatch = useDispatch();
  // State to manage the date range values
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null]);
  // State to manage the title input
  const [title, setTitle] = useState("");
  // State to manage the description input
  const [description, setDescription] = useState("");
  // State to manage the author input
  const [author, setAuthor] = useState("");
  // State to manage the role input
  const [role, setRole] = useState("");
  // State to manage the type input
  const [type, setType] = useState("");

  // Handler for form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission
    console.log("title: ", title); // Log the title to the console
    console.log("description: ", description); // Log the description to the console
    console.log("dateValues: ", dateValues); // Log the date values to the console
    console.log("author: ", author); // Log the author to the console
    console.log("role: ", role); // Log the role to the console
    console.log("type: ", type); // Log the type to the console
    const id = timeline.length + 1; // Generate a unique ID for the new event
    dispatch(
      changeCommentTimeline([
        ...timeline,
        { title, description, dateValues, author, role, type, id },
      ])
    ); // Dispatch the action to update the timeline

    handleClose(); // Close the dialog
    // Reset the form fields
    setTitle("");
    setDescription("");
    setDateValues([null, null]);
    setAuthor("");
    setRole("");
    setType("");
  };

  return (
    // Dialog component to display the form
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ px: 6, pt: 5 }}>
        <Typography variant="h4">New Event</Typography>
      </Box>

      {/* Form element with submit handler */}
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack useFlexGap spacing={4}>
            {/* Date range picker for event start and end */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimeRangePicker
                localeText={{ start: "Event start", end: "Event end" }}
                value={dateValues}
                onChange={(newValue) => {
                  setDateValues(newValue);
                }}
              />
            </LocalizationProvider>
            {/* Text field for title input */}
            <TextField
              id="title"
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTitle(event.target.value);
              }}
            />
            {/* Text field for description input */}
            <TextField
              id="outlined-textarea"
              label="Description"
              placeholder="Description"
              multiline
              fullWidth
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(event.target.value);
              }}
            />
            {/* Autocomplete for author input */}
            <Autocomplete
              id="author"
              freeSolo
              value={author}
              onChange={(event: any, newValue: string | null) => {
                if (newValue) setAuthor(newValue);
              }}
              options={exampleDoctors.map((option) => option.name)}
              renderInput={(params) => (
                <TextField {...params} label="Author" variant="outlined" />
              )}
            />

            {/* Text field for role input */}
            <TextField
              id="role"
              label="Role"
              variant="outlined"
              fullWidth
              value={role}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRole(event.target.value);
              }}
            />
            {/* Select field for type input */}
            <FormControl fullWidth>
              <InputLabel id="type">Type</InputLabel>

              <Select
                id="type"
                label="Type"
                value={type}
                onChange={(event: SelectChangeEvent) => {
                  setType(event.target.value as string);
                }}
                variant="outlined"
              >
                <MenuItem value="Note">Note</MenuItem>
                <MenuItem value="Observation">Observation</MenuItem>
                <MenuItem value="Appointment">Appointment</MenuItem>
                <MenuItem value="Procedure">Procedure</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          {/* Button to cancel and close the dialog */}
          <Button onClick={handleClose}>CANCEL</Button>
          {/* Button to submit the form */}
          <Button variant="contained" type="submit" color="primary">
            ADD NEW EVENT
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewEventForm;
