import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../Loader";

import { usePatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetQuery } from "../../api/jabsCentralApiPrivate";

const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const GetAdmissions: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  const {
    data: patientAdmissions,
    error: patientAdmissionsError,
    isLoading: isPatientAdmissionsLoading,
    isFetching: isPatientAdmissionsFetching,
  } = usePatientPatientIdAdmissionsGetPatientPatientIdAdmissionsGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  let content = null;

  if (isPatientAdmissionsLoading) {
    content = <Loader />;
  } else if (isPatientAdmissionsFetching) {
    content = <Loader />;
  } else if (patientAdmissionsError) {
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientAdmissionsError)
          ? JSON.stringify(patientAdmissionsError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientAdmissions) {
      content = <div>Select a patient to view raw API data</div>;
    } else {
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientAdmissions, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  return (
    <div>
      <Typography variant="h4">GET /patient/[patientId]/admissions</Typography>
      {content}
    </div>
  );
};

export default GetAdmissions;
