import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { Box, Divider } from "@mui/material";
import styled from "@emotion/styled";
import GetPatient from "./GetPatient";
import GetAdmissions from "./GetAdmissions";
import GetMeasurements from "./GetMeasurements";
import GetObservations from "./GetObservations";
import GetReports from "./GetReports";
import GetScores from "./GetScores";
import GetSummary from "./GetSummary";
import GetVitruvian from "./GetVitruvian";
import GetFiles from "./GetFiles";
import GetUpdates from "./GetUpdates";
import GetTimeline from "./GetTimeline";

const Container = styled(Box)`
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.024) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
  background-color: white;
  border-radius: 6px;
  height: calc(100vh - 187px);
  min-height: 662px;
  overflow: auto;
  padding: 25px;
`;

const PatientApiTester: React.FC = () => {
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  let content = null;
  if (selectedPatient === "null") {
    content = <div>Select a patient to view raw API data</div>;
  } else {
    content = (
      <React.Fragment>
        <GetPatient />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetAdmissions />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetMeasurements />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetObservations />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetReports />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetScores />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetSummary />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetVitruvian />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetFiles />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetUpdates />
        <Divider sx={{ mt: 4, mb: 6 }} />
        <GetTimeline />
      </React.Fragment>
    );
  }
  return <Container>{content}</Container>;
};

export default PatientApiTester;
