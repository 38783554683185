import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isFetchBaseQueryError } from "../../utils/isFetchBaseQueryError";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import Loader from "../Loader";

// Importing the API hook for fetching patient timeline data
import { usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery } from "../../api/jabsCentralApiPrivate";

// Styled component for wrapping text
const Wordwrap = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

// Main component for fetching and displaying patient timeline data
const GetTimeline: React.FC = () => {
  // Selecting the currently selected patient from the Redux store
  const selectedPatient = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );

  // Using the API hook to fetch patient timeline data
  const {
    data: patientTimeline,
    error: patientTimelineError,
    isLoading: isPatientTimelineLoading,
    isFetching: isPatientTimelineFetching,
  } = usePatientPatientIdTimelineGetPatientPatientIdTimelineGetQuery(
    {
      patientId: selectedPatient,
    },
    { refetchOnMountOrArgChange: true }
  );

  // Variable to hold the content to be displayed
  let content = null;

  // Handling different states of the API call
  if (isPatientTimelineLoading) {
    // Show loader while loading
    content = <Loader />;
  } else if (isPatientTimelineFetching) {
    // Show loader while fetching
    content = <Loader />;
  } else if (patientTimelineError) {
    // Show error message if there's an error
    content = (
      <div>
        Error:{" "}
        {isFetchBaseQueryError(patientTimelineError)
          ? JSON.stringify(patientTimelineError.data) // Convert to string
          : "An unknown error occurred"}
      </div>
    );
  } else {
    if (!patientTimeline) {
      // Show message if no patient is selected
      content = <div>Select a patient to view raw API data</div>;
    } else {
      // Show the patient timeline data
      content = (
        <div>
          <Wordwrap>{JSON.stringify(patientTimeline, null, 2)}</Wordwrap>
        </div>
      );
    }
  }

  // Render the component
  return (
    <div>
      <Typography variant="h4">GET /patient/[patientId]/timeline</Typography>
      {content}
    </div>
  );
};

export default GetTimeline;
