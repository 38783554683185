import React from "react";
import { Timeline } from "../../types/extraction";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

// Importing necessary components from MUI
import { Box } from "@mui/material";

interface TableTimelineProps {
  timeline: Timeline;
}

// Component to render a table timeline
const TableTimeline = ({ timeline }: TableTimelineProps) => {
  // Define the content of the component
  return (
    <Box sx={{ width: "100%" }}>
      <DataGridPro
        getRowHeight={() => "auto"}
        disableDensitySelector // Disable the density selector
        disableColumnSelector // Disable the column selector
        checkboxSelection
        slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolba
        slotProps={{
          toolbar: {
            showQuickFilter: true, // Show the quick filter in the toolbar
          },
        }}
        sx={{
          pt: 1,
          [`& .${gridClasses.cell}`]: {
            py: 4,
          },
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          sorting: { sortModel: [{ field: "event_date", sort: "asc" }] }, // Set the sorting column
        }}
        rows={timeline} // Set the rows of the DataGridPro to the timeline data
        columns={[
          // Define the columns for the DataGridPro
          {
            field: "event_date",
            headerName: "Start date",
            width: 120,
            type: "date",
            valueFormatter: (params) => {
              return params ? new Date(params).toLocaleDateString() : "N/A";
            },
          },
          {
            field: "event_end",
            headerName: "End date",
            width: 120,
            type: "date",
            valueFormatter: (params) => {
              return params ? new Date(params).toLocaleDateString() : "N/A";
            },
          },
          {
            field: "event_title",
            headerName: "Title",
            renderCell: (params) => {
              return <Box sx={{ fontWeight: 700 }}>{params.value}</Box>;
            },
            flex: 1,
            minWidth: 150,
          },
          {
            field: "author",
            headerName: "Author",

            flex: 1,
            minWidth: 150,
          },
          { field: "author_role", headerName: "Role", width: 150 },
          {
            field: "event_abstract",
            headerName: "Description",
            flex: 2,
            minWidth: 200,
          },
          { field: "event_type", headerName: "Type", width: 120 },
        ]}
        disableRowSelectionOnClick // Disable row selection on click
      />
    </Box>
  );
};

export default TableTimeline;
