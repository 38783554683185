import React from "react";
import { Box } from "@mui/material";
// import { Box, FormControl, InputAdornment, TextField } from "@mui/material";
// import { Search } from "@mui/icons-material";
import styled from "@emotion/styled";
import ChronologyEvent from "./ChronologyEvent";
import { Timeline } from "../../types/extraction";

// Styled component for the timeline container
const TimelineContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "20px",
}));

// Interface for the component props
interface TimelineProps {
  timeline: Timeline;
}

// Component to render a vertical timeline
const VerticalTimeline = ({ timeline }: TimelineProps) => {
  let content = null;

  // Sort and map the timeline events
  content = (
    <React.Fragment>
      <TimelineContainer>
        {timeline.map((event: any, index) => {
          // Determine the line type for the event
          var lineType = "middle";

          if (timeline.length === 1) {
            lineType = "single";
          } else if (index === 0) {
            lineType = "top";
          } else if (index === timeline.length - 1) {
            lineType = "bottom";
          }

          // Render the event with the appropriate line type
          return (
            <ChronologyEvent key={index} lineType={lineType} event={event} />
          );
        })}
      </TimelineContainer>
    </React.Fragment>
  );

  return content;
};

export default VerticalTimeline;
